import { Children, Component, MouseEvent } from 'react';
import ReactDOM from 'react-dom';

import { isNumber, isString } from '../../../utils/lodash-min';
import style from './tooltip.component.module.scss';
import { BrowserUtils } from '../../../ui-utils/browser-utils';

export interface TooltipProps {
    children?: any;
    text: string;
    shown?: boolean;
}

export interface TooltipState {
    shown: boolean;
}

const CARET_SIZE = 8;
const TOOLTIP_CONTAINER_MAX_WIDTH = 8;

export default class Tooltip extends Component<TooltipProps, TooltipState> {
    
    private tooltipContainer: HTMLSpanElement;
    private target;

    private windowEventsAdded = false;
    private onScrollPositionUpdateFn = (e) => {
        this.updateTooltipContainerPosition(e);
    };

    readonly state: Readonly<TooltipState> = {
        shown: this.props?.shown,
    };
    constructor(props: TooltipProps) {
        super(props);
    }

    private elementCenterX = (el) => {
        const bounds = el.getBoundingClientRect();
        return bounds.left + bounds.width / 2;
    }
    private elementCenterY = (el) => {
        const bounds = el.getBoundingClientRect();
        return bounds.top + bounds.height / 2;
    }

    private showTooltip(e: MouseEvent) {
        this.setState({
            shown: true,
        });
        if (!this.props.text || this.tooltipContainer) {
            return;
        }
        this.tooltipContainer = document.createElement('span');
        this.tooltipContainer.className = style.tooltip;
        this.tooltipContainer.style.top = `-1000px`;
        this.tooltipContainer.style.left = `-1000px`;
        this.tooltipContainer.textContent = this.props.text || '';

        const caretUp = document.createElement('span');
        caretUp.className = `caret-up ${style['caret-up']}`;
        this.tooltipContainer.appendChild(caretUp);

        document.body.appendChild(this.tooltipContainer);
        requestAnimationFrame(() => {
            if (e.target !== null) {
                this.target = e.target;
                this.updateTooltipContainerPosition(e);
                if (!this.windowEventsAdded) {
                    this.windowEventsAdded = true;
                    window.addEventListener('scroll', this.onScrollPositionUpdateFn);
                    window.addEventListener('mousemove', this.onScrollPositionUpdateFn);
                }
            }
        });

    }

    private updateTooltipContainerPosition(e?) {
        if (e && e.target && e.target.classList && (e.target.classList.contains(style.tooltip) || e.target.classList.contains('caret-up'))) {
            return;
        }
        try {
            const target: HTMLElement = this.target as any;
            if (!target || !target.isConnected) {
                this.hideTooltip();
            }
            requestAnimationFrame(() => {
                this.placeTooltip();
            });
            requestAnimationFrame(() => {
                this.placeTooltip();
            });
        } catch (e) {
            this.removeWindowEventHandlers();
            this.hideTooltip();
        }
    }

    private placeTooltip() {
        try {
            const tooltipRect = this.getTooltipRect();
            const targetRect = this.getTargetRect();
            let targetCenterX = targetRect.left + targetRect.width / 2;
            let targetCenterY = targetRect.top + targetRect.height / 2;


            const canShowOnLeft = targetRect.left > (TOOLTIP_CONTAINER_MAX_WIDTH + 10);
            const canShowOnRight = targetRect.right + (TOOLTIP_CONTAINER_MAX_WIDTH + 10) < window.innerWidth;
            const canShowOnTop = targetRect.top > (tooltipRect.height + 10);
            const canShowOnBottom = window.innerHeight - targetRect.bottom > tooltipRect.width;

            let top;
            let left;
            let bottom;
            let right;
            if (canShowOnTop) {
                top = targetRect.top - (tooltipRect.height + 10);
                left = Math.min(targetCenterX + (tooltipRect.width / 2), window.innerWidth) - (tooltipRect.width);
            } else if (canShowOnBottom) {
                // Displaying bottom
                top = targetRect.bottom + 10;
                left = Math.min(targetCenterX + (tooltipRect.width / 2), window.innerWidth) - (tooltipRect.width);
            }

            this.applyStyleToToolTip({ top, left, bottom, right });
            this.updateCaretPosition();
        } catch (e) {
            this.removeWindowEventHandlers();
            this.hideTooltip();
        }
    }

    private updateCaretPosition() {
        requestAnimationFrame(() => {
            try {
                const tooltipPosition = this.getTooltipRect();
                const targetPosition = this.getTargetRect();
                const caret = this.tooltipContainer.querySelector<HTMLSpanElement>('.caret-up');
                const caretRect = caret.getBoundingClientRect();
                if (tooltipPosition.top > targetPosition.bottom) {
                    // tooltip is located below the target
                    const caretLeft = this.elementCenterX(this.target) - (tooltipPosition.left + CARET_SIZE / 2);
                    const caretTop = -1 * CARET_SIZE / 2;

                    caret.style.left = `${caretLeft}px`;
                    caret.style.top = `${caretTop}px`;
                } else if (tooltipPosition.top < targetPosition.bottom) {
                    // tooltip is located TOP of the target
                    const caretLeft = this.elementCenterX(this.target) - (tooltipPosition.left + CARET_SIZE / 2);
                    const careTop = -1 * CARET_SIZE / 2 + tooltipPosition.height;
                    caret.style.left = `${caretLeft}px`;
                    caret.style.top = `${careTop}px`;
                    caret.style.transform = `rotate(${315}deg)`;
                }
            } catch (e) {
            }
        });
    }

    private getTooltipRect(): DOMRect {
        return this.tooltipContainer.getBoundingClientRect();
    }

    private getTargetRect(): DOMRect {
        return this.target.getBoundingClientRect();
    }

    private applyStyleToToolTip(calculatedStyles: { top?: number, left?: number, right?: number, bottom?: number }) {
        Object.keys(calculatedStyles).forEach(key => {
            if (!calculatedStyles[key]) {
                calculatedStyles[key] = 'unset';
                return;
            }
            calculatedStyles[key] = `${calculatedStyles[key]}px`;
        })
        const completeStyles = Object.assign({ top: 'unset', left: 'unset', right: 'unset', bottom: 'unset' }, calculatedStyles);
        Object.assign(this.tooltipContainer.style, completeStyles);
    }

    private hideTooltip(e?) {
        if (this.tooltipContainer && this.tooltipContainer.isConnected) {
            this.setState({
                shown: false,
            });
        }
        this.removeWindowEventHandlers();
        if (this.tooltipContainer) {
            setTimeout(() => {
                this.tooltipContainer && this.tooltipContainer.remove();
                this.tooltipContainer = null;
            }, 5);
        }
    }

    private removeWindowEventHandlers() {
        window.removeEventListener('scroll', this.onScrollPositionUpdateFn);
        window.removeEventListener('mousemove', this.onScrollPositionUpdateFn);
    }

    componentDidMount() {
        if (BrowserUtils.isMobile()) {
            return;
        }
        const element = ReactDOM.findDOMNode(this) as HTMLElement;
        element.onmouseenter = (e) => this.showTooltip(e as any);
        element.onmouseleave = (e) => this.hideTooltip(e as any);
    }

    componentWillUnmount() {
        this.hideTooltip();
    }

    render(): JSX.Element {
        const firstChild = Children.toArray(this.props.children)[0];
        if (isString(firstChild) || isNumber(firstChild)) {
            return <span>
                {firstChild}
            </span>;
        }
        return firstChild as any;
    }
}